<template>
    <div class="SysOutCreate">
        <el-card>
            <el-page-header @back="goBack" />
        </el-card>
        <el-card style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: right" v-model="form" size="small">
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="入库单号">
                            <el-input placeholder="关键字" v-model="form.code" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleQuery" size="small">查询</el-button>
            <el-button type="primary" @click="handleSave" size="small">保存</el-button>
        </el-card>
        <el-card style="margin-top: 8px">
            <el-table
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="500"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column prop="code" label="商品编码" width="180" />
                <el-table-column prop="sku.name" label="商品名称" width="180" />
                <el-table-column prop="sku.bars" label="条码" width="180" />
                <el-table-column prop="fastBar" label="助记码" width="100" />
                <el-table-column prop="customBar" label="自编码" width="100" />
                <el-table-column prop="sku.specs" label="规格" width="100" />
                <el-table-column prop="sku.category" label="类目" width="120" />
                <el-table-column prop="sku.unit" label="单位" width="100" />
                <el-table-column prop="sku.brand" label="品牌" width="150" />
                <el-table-column prop="count" label="出库数量" width="120">
                    <template slot-scope="scope">
                        <ef-price-input v-model="scope.row.count" v-if="scope.row.sku.type" :precision="3" />
                        <ef-price-input v-model="scope.row.count" v-else :precision="0" />
                    </template>
                </el-table-column>
                <el-table-column prop="wholeSalePrice" label="批发价" width="100">
                    <template slot-scope="scope"
                        ><span>{{ (scope.row.wholeSalePrice / 10000).toFixed(2) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="retailPrice" label="零售价" width="100">
                    <template slot-scope="scope"
                        ><span>{{ (scope.row.retailPrice / 10000).toFixed(2) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="100" header-align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" type="danger" @click="deleteRow(scope.$index, tableData, scope.row)"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import EfPriceInput from 'components/EfPriceInput';

export default {
    name: 'SysOutCreate',
    components: { EfPriceInput },
    data() {
        return {
            form: {
                code: '',
                deptCode: '',
            },
            tableData: [],
            url: {
                page: '/purchase/sysIn/info_c/',
                queryDetail: '/goods/goods/list_c',
                save: '/purchase/sysOut/createExtend',
            },
        };
    },
    methods: {
        async handleQuery() {
            if (!this.form.code.trim()) {
                this.$message.error('请输入入库单号');
                return;
            }
            await Util.queryTable(this, this.url.page + this.form.code, null, (data) => {
                const sysInPo = data.data;
                this.form.deptCode = sysInPo.deptCode;
                const _params = {
                    params: { codes: sysInPo.goodsCodes.map((d) => d).join(',') },
                };
                Util.queryTable(this, this.url.queryDetail, _params, (rst) => {
                    rst.data.forEach((v, i) => {
                        v.count = sysInPo.counts[i];
                        v.codes = sysInPo.codes[i];
                        v.retailPrice = sysInPo.retailPrices[i];
                        v.wholeSalePrice = sysInPo.wholeSalePrices[i];
                    });
                    this.tableData = rst.data;
                });
            });
        },
        handleSave() {
            const _params = {
                deptCode: this.form.deptCode,
                sysInCode: this.form.code,

                sysInDetailCodes: this.tableData.map((d) => d.codes),
                goodsCodes: this.tableData.map((d) => d.code),
                counts: this.tableData.map((d) => d.count),
                wholeSalePrices: this.tableData.map((d) => d.wholeSalePrice),
                retailPrices: this.tableData.map((d) => d.retailPrice),
                prices: this.tableData.map((d) => d.wholeSalePrice),
            };
            UrlUtils.PostRemote(this, this.url.save, _params);
        },
        deleteRow(index, rows, row) {
            rows.splice(index, 1);
        },
    },
};
</script>

<style scoped>
.Supplier .el-form-item {
    margin-bottom: 0;
}
</style>
